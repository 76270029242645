import * as React from 'react';

import About from 'screens/About';

import { GlobalLayout } from 'components/Layout';

export default function Index() {
  return (
    <GlobalLayout>
      <About />
    </GlobalLayout>
  );
}

import { styled } from '@linaria/react';

import { MEDIA } from '@singledesk/theme';

import { LogoOutlined } from 'theme/icons/logo';

export const Wrapper = styled.main`
  position: relative;

  overflow: hidden;
`;

export const PseudoLogo = styled(LogoOutlined)`
  position: absolute;
  z-index: -1;
  top: -250px;
  right: 10px;

  width: 240px;
  height: 240px;
  flex-shrink: 0;

  @media (min-width: ${MEDIA.tablet}) {
    top: -250px;

    width: 444px;
    height: 444px;
  }

  @media (min-width: ${MEDIA.desktop}) {
    top: -400px;

    width: 780px;
    height: 780px;
  }
`;

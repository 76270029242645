import { styled } from '@linaria/react';

import { MEDIA } from '@singledesk/theme';

export const Wrapper = styled.div`
  display: grid;
  gap: 24px;
  padding-bottom: 80px;

  @media (min-width: ${MEDIA.tablet}) {
    gap: 40px;
  }
`;

export const Description = styled.p`
  max-width: 640px;
`;

import * as React from 'react';

import { SectionWrapper } from 'components/Layout';
import GetAccessForm from 'components/GetAccessForm';

import { Wrapper, Description } from './styled';

const Price = () => (
  <SectionWrapper>
    <Wrapper>
      <h3>{'Информация о стоимости использования продукта'}</h3>

      <Description>
        Стоимость использования ПО рассчитывается индивидуально. Для получения
        информации о ценах и вариантах установки, пожалуйста, свяжитесь с нами!
      </Description>

      <GetAccessForm />
    </Wrapper>
  </SectionWrapper>
);

export default Price;
